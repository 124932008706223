import { useEffect, useState } from 'react';
import {
  Row,
  Input,
  Form,
  Button,
  message,
  Alert,
  Radio,
  Space,
  Spin,
} from 'antd';
import { useSendEKbaTextMutation, useVerifyKbaQuestionsMutation } from '../../../../../graphql/mutations/kba';
import { useKbaQuestionsAndAnswersQuery } from '../../../../../graphql/queries/kba';

const EnhancedKBA = ({
  kbaId,
  numKbaFailedAttempts,
  numFailedIdScans,
  phoneValue,
}) => {
  const [numAttemptsRemaining, setNumAttemptsRemaining] = useState(3 - numKbaFailedAttempts);
  const [textMessageSent, setTextMessageSent] = useState(false);
  const [randomQuestions, setRandomQuestions] = useState([]);
  const [callSendEKbaText, { loading: loadingSendEKbaText }] = useSendEKbaTextMutation();
  const [verifyKbaQuestions, { loading: loadingVerifyKbaQuestions }] = useVerifyKbaQuestionsMutation();
  const { data: kbaQAData, loading: loadingkbaQAData, error: kbaQADataError } = useKbaQuestionsAndAnswersQuery({
    variables: {
      kbaId,
    },
  });
  const { getKBAQuestionAnswers } = kbaQAData || {};
  const { KBAQuestions } = getKBAQuestionAnswers || {};
  console.log('KBAQuestions:', KBAQuestions);

  useEffect(() => {
    const rQuestions = KBAQuestions ? [...KBAQuestions].sort(() => 0.5 - Math.random()).slice(0, 5) : [];
    console.log('rQuestions:', rQuestions);
    setRandomQuestions(rQuestions)
  }, [KBAQuestions])

  const onFinishSendTextMessage = async (values) => {
    const { phoneNumber } = values;
    try {
      const result = await callSendEKbaText({
        variables: {
          kbaId,
          phoneNumber,
        }
      });
      console.log('result:', result);
      if (result.data.sendEKbaText) {
        message.success('Text message sent');
        setTimeout(() => {
          setTextMessageSent(true);
        }, 300);
      }
    } catch (error) {
      message.error(error.toString());
      if (error.toString() === 'Error: KBA Failed.') {
        setNumAttemptsRemaining((prev) => prev - 1);
      }
    }
  };
  
  const onFinishEnhancedKba = async (values) => {
    console.log('values:', values);
    const questionAnswers = [];
    for (const [key, value] of Object.entries(values)) {
      questionAnswers.push({
        questionId: key,
        answerId: value,
      });
    }
    try {
      const result = await verifyKbaQuestions({
        variables: {
          kbaId,
          questionAnswers,
        }
      });
      console.log('result:', result);
      if (result.data.verifyKBAQuestions.isValidated) {
        message.success('Identity has been verified!');
        setTimeout(() => {
          window.location.reload();
        }, 300);
      }
    } catch (error) {
      message.error(error.toString());
      if (error.toString() === "Error: Verification Failed.") {
        setNumAttemptsRemaining((prev) => prev - 1);
      }
    }
  }

  // if still loading
  if (loadingkbaQAData) {
    return (
      <Row justify="center" align="middle" style={{ height: '90vh' }}>
        <Spin size="large" />
      </Row>
    )
  }

  // if questions available for answering
  if (randomQuestions?.length > 0) {
    return (
      <div
        style={{
          maxWidth: '850px',
          margin: '0 auto',
          textAlign: 'center',
          paddingBottom: '50px',
        }}
      >
        <Row
          justify="center"
          style={{
            marginBottom: '20px',
            marginTop: '50px',
          }}
        >
          {(numAttemptsRemaining <= 2) && (
            <Alert
              description={numAttemptsRemaining > 0
                ? `Only ${numAttemptsRemaining} attempts remaining.`
                : `Please contact your tax preparer if you need access to this document.`}
              message={numAttemptsRemaining > 0
                ? undefined
                : `No more attempts remaining.`}
              type={numAttemptsRemaining > 0 ? 'warning' : 'error'}
              showIcon
              style={{ marginBottom: 20, marginTop: -5 }}
            />
          )}
          <h1>
            Please answer the following questions to verify your identity.
          </h1>
        </Row>
        <Form
          name="enhanced_kba"
          layout="vertical"
          onFinish={onFinishEnhancedKba}
          style={{ textAlign: 'left' }}
        >
          {randomQuestions.map((question) => (
            <Form.Item
              name={question.id}
              label={<b>{question.text}</b>}
              rules={[{ required: true, message: 'This question is required.' }]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  {question.kbaAnswers.map((answer) => (
                    <Radio key={answer.KBAQuestionId} value={answer.id}>{answer.text}</Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          ))}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: '100%' }}
              loading={loadingVerifyKbaQuestions}
            >
              Verify
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  // if text message is sent, but still waiting on identiflo
  if (textMessageSent) {
    const AlertDescription = () => (
      <>
        <p>
          1. Check your device for a text message and click on the link provided.
        </p>
        <p>
          2. Complete ID scan by uploading the necessary documents.
        </p>
        <p>
          3. Upon successful ID upload, refresh this page to complete verification (may need to wait a couple minutes before refreshing).
        </p>
      </>
    )
    return (
      <div
        style={{
          maxWidth: '650px',
          margin: '0 auto',
          textAlign: 'center',
          paddingBottom: '50px',
        }}
      >
        <Row
          justify="center"
          style={{
            marginBottom: '20px',
            marginTop: '50px',
          }}
        >
          <h1>
            Request Sent!
          </h1>
          <Alert
            type="info"
            showIcon
            description={<AlertDescription />}
            message="Important."
            banner
          />
        </Row>
      </div>
    );
  }

  // if initial contact and they still need a text message sent
  return (
    <div
      style={{
        maxWidth: '450px',
        margin: '0 auto',
        textAlign: 'center',
        paddingBottom: '50px',
      }}
    >
      <Row
        justify="center"
        style={{
          marginBottom: '20px',
          marginTop: '50px',
        }}
      >
        {(numFailedIdScans > 0) && (
          <Alert
            message="Your ID scan failed. Please try again."
            description="Failed ID scans are usually due to blurry images or poor lighting. If the problem persists, please contact your tax preparer."
            type="error"
            showIcon
            closable
            style={{ marginBottom: 20, marginTop: -5 }}
          />
        )}
        {(numAttemptsRemaining <= 2) && (
          <Alert
            description={numAttemptsRemaining > 0
              ? `Only ${numAttemptsRemaining} attempts remaining.`
              : `No more attempts remaining.`}
            type={numAttemptsRemaining > 0 ? 'warning' : 'error'}
            showIcon
            closable
            style={{ marginBottom: 20, marginTop: -5 }}
          />
        )}
        <h1>
          Please verify your identity to access documents.
        </h1>
        <p style={{ color: 'gray' }}>
          After pressing continue you will receive a text message with additional steps from our identity provider.
        </p>
      </Row>
      <Form
        name="enhanced_kba"
        layout="vertical"
        onFinish={onFinishSendTextMessage}
      >
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          initialValue={phoneValue}
          rules={[{ required: true, message: 'Please input your phone number.' }]}
        >
          <Input disabled value={phoneValue} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: '100%' }}
            loading={loadingSendEKbaText}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EnhancedKBA;
